import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';
import './App.css';
import SocialNavbar from './SocialNavbar'; // Import the SocialNavbar component


function App() {
  const [chartData, setChartData] = useState({
    labels: ['Liquidity Pool 💦', 'Marketing + KOLs 📢', 'Team', 'Future Utility'],
    datasets: [
      {
        data: [90, 5, 3,2],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FFCE90'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FFCE90'],
      },
    ],
  });

  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartInstance) {
      chartInstance.destroy(); // Ensure the existing chart is destroyed
    }

    // Create a new chart instance
    const newChartInstance = new Chart(document.getElementById('myChart'), {
      type: 'pie',
      data: chartData,
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'right', // You can adjust the position as needed
          },
        },
        tooltips: {
          enabled: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            // Disable displaying the color box
            tooltip.displayColors = false;
          },
          callbacks: {
            label: function(context) {
              const value = context.dataset.data[context.dataIndex];
              const total = context.dataset.data.reduce((a, b) => a + b, 0);
              const percent = ((value / total) * 100).toFixed(2);
              return `${percent}%`;
            },
          },
        },
        hover: {
          mode: null,
        },
      },
    });
    // Set the new chart instance to state
    setChartInstance(newChartInstance);

    // Cleanup: Destroy the chart when the component is unmounted
    return () => {
      newChartInstance.destroy();
    };
  }, [chartData]);


  return (
    <div className="App">
      <header className="App-header">
      <div className="featured-content">
            
           
            <div className="featured-content">

            <div className="featured-content">
            
            <img src={require('./kelce.png')} className="rotateImage"/>
            </div>
            
            <img src={require('./kelce.png')} className="no-spin" style={{ boxShadow: 'none' }}/>

            <div className="featured-content">
            
            <img src={require('./kelce.png')} className="rotateImage"/>
            </div>
            {/* <img src={require('./featured-image2.jpg').default} alt="Featured 2" />
            <img src={require('./featured-image3.jpg').default} alt="Featured 3" /> */}
          </div>
          
            {/* <img src={require('./featured-image2.jpg').default} alt="Featured 2" />
            <img src={require('./featured-image3.jpg').default} alt="Featured 3" /> */}
          </div>

          <div style={{flex:'column'}}>
          <SocialNavbar /> {/* Add the SocialNavbar component here */}
          <div style={{padding:'10px'}}></div>
        <h1 className="App-title">$KELCE</h1>
        <hp className="App-title">CA: 0xa9E6d6B6c619b20e6ab09cBdfF0f3f6F1f800A1b</hp>
        <h3>https://www.dextools.io/app/en/ether/pair-explorer/0xd938b4757d5d56620c1d5f472bdedf5937125b87?t=1707686933926</h3>
        <h3>kelcecoin@gmail.com</h3>
        <div style={{padding:'10px'}}></div>
        </div>
      </header>

      <main className="App-main">
      
     
        <div className="content">
       
    
    
      


            <div>
  <img src={require('./kelce2.png')} alt="Kelce" style={{ width: '100%', height: 'auto' , position:'absolute', top:'150%', left:'20%'}} />
  <div style={{ backgroundColor: 'red', paddingTop: '10px', width: '100%', paddingBottom: '20%', borderRadius:'25px'}}>


    
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
  <div style={{ borderRadius: '25px', boxShadow: '0 0 30px rgba(1, 0, 1, 0.8)', width: '80%', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
         
    
         <h1 className="App-title" style={{ textAlign: 'center', fontSize: '4em' }}>Stats</h1>
         <p className="App-title" style={{ textAlign: 'center', fontSize: '2em' , color: 'white' }}>LP Locked 🔥</p>
         <p className="App-title" style={{ textAlign: 'center', fontSize: '2em', color: 'white'  }}>Contract Renounced</p>
     </div>

     <span style={{height:'2rem'}}></span>
  <div style={{ borderRadius: '25px', boxShadow: '0 0 30px rgba(1, 0, 1, 0.8)', width: '80%', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
    <h1 className="App-title" style={{ textAlign: 'center', fontSize: '4em', margin: '20px 0' }}>Tokenomics</h1>
    <p className="App-title" style={{ textAlign: 'center', fontSize: '2em', color: 'white' }}>95% Liquidity Pool 💦</p>
    <p className="App-title" style={{ textAlign: 'center', fontSize: '2em', color: 'white' }}>5%Team 📢</p>
https://www.dextools.io/app/en/ether/pair-explorer/0xd938b4757d5d56620c1d5f472bdedf5937125b87?t=1707686933926
  </div>
</div>

    {/* <div className="pie-chart" style={{ width: '40%', height: '40%', backgroundColor: 'white', borderRadius: '25%', margin: '0 auto' }}>
      <canvas id="myChart" />
    </div> */}
  </div>
</div>


          

          {/* <div className="pie-chart">
        <Pie data={chartData} onElementsClick={(elements) => console.log(elements)} />
      </div>
           */}
         

        </div>
       
      </main>
    

      <img src={require('./KelceLogo.png')}  style={{ width: '20%', height: 'auto' , position:'absolute'}} />
    </div>
  );
}

export default App;
