import React from 'react';

const SocialNavbar = () => {
  return (
    <div className="social-navbar" style={{ 
      display: 'inline-flex',
      border: '4px solid',
      animation: 'rainbowBorder 4s linear infinite',
      borderRadius: '25px',
      borderBottom: '20px solid',
      margin: '0', /* Set margin to zero */
      padding: '10px' /* Set padding to zero */
      , boxShadow: '0 0 30px rgba(1, 0, 1, 0.8)',
      backgroundColor: 'rgba(255, 255, 255, 0.7)'
    }}>

      <h2 style={{color:'black' }}>Our Social Media</h2>

      <a
        href="https://t.me/+kCp8Ok0uM7M1M2I8"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon"
        style={{ margin: '5px' }}
      >
        <img
          src={require('./telegram.png')}
          alt="Twitter"
          className="social-icon-img"
          style={{ borderRadius: '50%', padding: '2px' }}
        />
      </a>

      <a
        href="https://twitter.com/kelce_coin"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon"
        style={{ margin: '5px' }}
      >
        <img
          src={require('./x.png')}
          alt="Twitter"
          className="social-icon-img"
          style={{ borderRadius: '50%', padding: '2px' }}
        />
      </a>

      <a
        href="https://etherscan.io/address/0xa9e6d6b6c619b20e6ab09cbdff0f3f6f1f800a1b"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon"
        style={{ margin: '5px' }}
      >
        <img
          src={require('./etherscan-icon.png')}
          alt="Etherscan"
          className="social-icon-img"
        />
      </a>

      <a
        href="https://dexscreener.com/ethereum/0xd938b4757d5d56620c1d5f472bdedf5937125b87"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon"
        style={{ margin: '5px' }}
      >
        <img
          src={require('./dexscreener-300.png')}
          alt="Etherscan"
          className="social-icon-img"
        />
      </a>
     
    </div>
  );
};

export default SocialNavbar;
